<template>
  <div v-if="loading" class="relative mx-auto p-5">
    <Spinner class="mx-auto" size="large" />
  </div>

  <div v-else class="relative mx-auto space-y-10 pb-5">
    <Header
      v-if="!headerHidden"
      :height="header.height"
      :background-url="header.background_url"
      :logo-url="header.logo_url"
      :title="header.title"
      :font-url="header.font_url"
      :font-style="header.font_style"
      :library-url="libraryUrl()"
    />

    <Base class="xl:container mx-auto px-5" :class="{ 'pt-10': headerHidden }">
      <div class="max-w-md mx-auto">
        <label hidden for="search">Search</label>
        <div class="relative rounded-md shadow-sm flex items-center">
          <div class="absolute inset-y-0 left-0 px-3 flex items-center pointer-events-none">
            <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            id="search"
            ref="searchInput"
            type="text"
            class="focus:ring-indigo-500 focus:border-indigo-500 block w-full px-10 text-md border-gray-300 rounded-md"
            placeholder="Search"
            maxlength="128"
            @keyup="search($event.target.value)"
          />
          <button
            v-if="searching"
            type="button"
            class="absolute inset-y-0 right-0 px-3 flex items-center group"
            @click="clearSearch"
          >
            <XIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-700" aria-hidden="true" />
          </button>
        </div>
      </div>
    </Base>

    <Base v-if="searching" class="xl:container mx-auto space-y-10 px-5">
      <div v-if="searchLoading" class="relative mx-auto p-5 pt-0">
        <Spinner class="mx-auto" size="large" />
      </div>

      <Base v-if="!searchLoading && searchResults.length > 0" class="space-y-5">
        <Heading
          :id="groupId('search-results')"
          level="h2"
          custom-class="text-gray-800 text-2xl tracking-normal font-semibold"
          >Search results</Heading
        >
        <Grid :cols="4" class="gap-5">
          <template v-for="item in searchResults" :key="[item.item_type, item.item_type_data?.id].join('/')">
            <PresentationBox
              v-if="item.item_type === 'slideslive_presentation'"
              :title="item.item_type_data.title"
              :thumbnail-url="item.item_type_data.thumbnail_url"
              :href="slideslivePresentationHref(item.custom_group_uuid, item.item_type_data.id)"
              target=""
            />
            <PresentationBox
              v-else-if="item.item_type === 'external_url'"
              :title="item.item_type_data.title"
              :thumbnail-url="item.item_type_data.thumbnail_url"
              :href="item.item_type_data.external_url"
              target="_blank"
              rel="noopener noreferrer"
            />
          </template>
        </Grid>
      </Base>

      <Base v-if="!searchLoading && searchResults.length === 0" class="space-y-5 text-center">
        <Heading level="h2" custom-class="text-gray-800 text-xl tracking-normal font-semibold">No results for</Heading>
        <Text class="text-2xl text-center mt-2">"{{ searchQuery }}"</Text>
      </Base>
    </Base>

    <Base v-if="!searching" class="xl:container mx-auto space-y-10 px-5">
      <template v-for="group in orderedGroups" :key="group">
        <Base class="space-y-5">
          <Heading
            :id="groupId(group)"
            level="h2"
            custom-class="text-gray-800 text-2xl tracking-normal font-semibold"
            >{{ group }}</Heading
          >
          <Grid :cols="4" class="gap-5">
            <template
              v-for="item in groupedItems[group].items"
              :key="[group, item.item_type, item.item_type_data?.id].join('/')"
            >
              <PresentationBox
                v-if="item.item_type === 'slideslive_presentation'"
                :title="item.item_type_data.title"
                :thumbnail-url="item.item_type_data.thumbnail_url"
                :href="slideslivePresentationHref(item.custom_group_uuid, item.item_type_data.id)"
                target=""
              />
              <PresentationBox
                v-else-if="item.item_type === 'external_url'"
                :title="item.item_type_data.title"
                :thumbnail-url="item.item_type_data.thumbnail_url"
                :href="item.item_type_data.external_url"
                target="_blank"
                rel="noopener noreferrer"
              />
            </template>
          </Grid>
        </Base>
      </template>
    </Base>
  </div>
</template>

<script>
import { SearchIcon, XIcon } from '@heroicons/vue/solid';

import { useTitle } from '@vueuse/core';

import { getLibrary, search } from '@/api';
import Base from '@/fuse/components/Base.vue';
import Text from '@/fuse/components/Text.vue';
import Grid from '@/fuse/components/Grid.vue';
import Heading from '@/fuse/components/Heading.vue';
import Spinner from '@/fuse/components/Spinner.vue';

import Header from './library_iframe/Header.vue';
import PresentationBox from './library_iframe/PresentationBox.vue';

export default {
  components: {
    SearchIcon,
    XIcon,
    Base,
    Text,
    Grid,
    Heading,
    Spinner,
    Header,
    PresentationBox,
  },

  data() {
    return {
      loading: true,
      loadingItems: false,

      searching: false,
      searchLoading: true,
      searchTimeout: null,
      searchResults: [],
      searchQuery: null,
      searchPage: 0,
      searchOnLastPage: false,
      searchCounter: 0,

      header: {},
      items: [],
      autoLoadItems: true,

      currentItemsSource: null,
      currentItemsPage: 0,
      onLastItemsPage: false,
      scrollListener: null,
    };
  },

  computed: {
    libraryUuid() {
      return this.$route.params.libraryUuid;
    },

    jwt() {
      return this.$route.query.jwt;
    },

    headerHidden() {
      return this.header.height === 0 || this.header.height === '0' || this.header.height === '0px';
    },

    orderGroupsAndGroupedItems() {
      const orderedGroups = [];
      const groupedItems = {};
      for (const item of this.items) {
        if (!groupedItems[item.group]) {
          orderedGroups.push(item.group);
          groupedItems[item.group] = { group: item.group, items: [] };
        }

        groupedItems[item.group].items.push(item);
      }

      return { orderedGroups, groupedItems };
    },

    orderedGroups() {
      return this.orderGroupsAndGroupedItems.orderedGroups;
    },

    groupedItems() {
      return this.orderGroupsAndGroupedItems.groupedItems;
    },
  },

  mounted() {
    this.loadItems({ page: 1 });

    this.scrollListener = () => {
      this.tryLoadMoreItems();
      this.tryLoadMoreSearchResults();
    };
    window.addEventListener('scroll', this.scrollListener);
  },

  unmounted() {
    window.removeEventListener('scroll', this.scrollListener);
  },

  methods: {
    groupId(groupName) {
      if (!groupName.normalize) {
        return 'group';
      }

      return groupName
        .toLowerCase()
        .normalize('NFKD')
        .replace(/[^a-z0-9 ]/gi, '')
        .replace(/ +/g, '-')
        .replace(/-+/, '-');
    },

    libraryUrl() {
      const jwt = this.$route.query.jwt || '';
      return `/libraries/${this.$route.params.libraryUuid}?jwt=${jwt}`;
    },

    slideslivePresentationHref(customGroupUuid, presentationId) {
      const jwt = this.$route.query.jwt || '';
      return `/libraries/${
        this.$route.params.libraryUuid
      }/presentations/${presentationId}?jwt=${jwt}&custom_group_uuid=${customGroupUuid || ''}`;
    },

    loadItems({ page = 1 } = {}) {
      this.loadingItems = true;

      getLibrary({
        libraryUuid: this.$route.params.libraryUuid,
        source: this.currentItemsSource,
        page,
        jwt: this.$route.query.jwt,
      })
        .catch((err) => {
          console.warn(err);

          this.$store.dispatch('showAlert', {
            type: 'error',
            content: 'Library loading failed. Check your internet connection.',
            dismissIn: 60000000,
          });

          return null;
        })
        .then((json) => {
          if (!json) return;

          if (!json.success) {
            this.$store.dispatch('showAlert', {
              type: 'error',
              content: `Library loading failed. ${json.errors.join(' ')}<br>Error: <span class="text-xs font-mono">${
                json.code
              }</span>`,
              dismissIn: 60000000,
            });

            return;
          }

          this.header = json.data.header;
          useTitle(this.header.title);

          console.log(this.header.height === '0');

          this.items = this.items.concat(json.data.items);

          if (json.data.last_page) {
            if (json.data.next_source) {
              this.currentItemsSource = json.data.next_source;
              this.currentItemsPage = 0;
            } else {
              this.onLastItemsPage = true;
              this.currentItemsPage = json.data.page;
            }
          } else {
            this.currentItemsSource = json.data.source;
            this.currentItemsPage = json.data.page;
          }

          this.loading = false;
          this.loadingItems = false;

          this.tryLoadMoreItems();
        });
    },

    tryLoadMoreItems() {
      if (this.searching) {
        return;
      }

      if (this.onLastItemsPage) {
        return;
      }

      if (this.loading || this.loadingItems) {
        return;
      }

      if (
        !this.autoLoadItems &&
        document.documentElement.scrollTop + window.innerHeight < document.documentElement.scrollHeight
      ) {
        return;
      }

      this.loadItems({ page: this.currentItemsPage + 1 });
    },

    tryLoadMoreSearchResults() {
      if (!this.searching) {
        return;
      }

      if (this.searchOnLastPage) {
        return;
      }

      if (this.searchLoading) {
        return;
      }

      if (document.documentElement.scrollTop + window.innerHeight < document.documentElement.scrollHeight) {
        return;
      }

      this.loadSearch({ searchId: this.searchCounter, page: this.searchPage + 1 });
    },

    search(query) {
      if (this.searchQuery === query) {
        return;
      }

      this.searching = true;
      this.searchLoading = true;
      this.searchResults = [];
      this.searchQuery = query;
      this.searchPage = 1;
      this.searchOnLastPage = false;
      this.searchCounter += 1;

      if (this.searchQuery === '') {
        this.searching = false;
        return;
      }

      const searchId = this.searchCounter;

      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        this.loadSearch({ searchId, page: 1 });
      }, 250);
    },

    loadSearch({ searchId, page }) {
      this.searchPage = page;

      search({
        libraryUuid: this.libraryUuid,
        query: this.searchQuery,
        page: this.searchPage,
        jwt: this.jwt,
      }).then((json) => {
        if (this.searchCounter !== searchId) {
          return;
        }

        this.searchResults = this.searchResults.concat(json.data.items);
        this.searchOnLastPage = json.data.last_page;
        this.searchLoading = false;

        this.tryLoadMoreSearchResults();
      });
    },

    clearSearch() {
      this.$refs.searchInput.value = '';
      this.searching = false;
    },
  },
};
</script>
