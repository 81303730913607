import Index from './pages/Index.vue';
import LibrariesNew from './pages/libraries/New.vue';
import LibrariesEdit from './pages/libraries/Edit.vue';
import Login from './pages/Login.vue';

export default [
  { path: '/admin', name: 'admin/index', component: Index, meta: { requiresAdminAuth: true } },
  { path: '/admin/log_in', name: 'admin/login', component: Login },
  {
    path: '/admin/libraries/new',
    name: 'admin/libraries/new',
    component: LibrariesNew,
    meta: { requiresAdminAuth: true },
  },
  {
    path: '/admin/libraries/:libraryUuid/edit',
    name: 'admin/libreries/edit',
    component: LibrariesEdit,
    meta: { requiresAdminAuth: true },
  },
];
