<template>
  <InlineSvg :src="computedSrc" />
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg,
  },

  props: {
    name: { type: String, required: true },
  },

  computed: {
    computedSrc() {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/icon/${this.name}.svg`);
    },
  },
};
</script>
