<template>
  <Base
    class="relative rounded-lg overflow-hidden pb-9/16 group-hover:rounded-lg group-hover:scale-105 transform-gpu transition-box duration-300"
  >
    <Base class="absolute inset-0 block">
      <a :href="href" :target="target">
        <img class="absolute inset-0 m-auto w-full" :src="src" alt="Presentation thumbnail" />
      </a>
    </Base>
  </Base>
</template>

<script>
import Base from '@/fuse/components/Base.vue';

export default {
  components: {
    Base,
  },

  props: {
    src: { type: String, required: true },
    href: { type: String, required: true },
    target: { type: String, required: true },
  },
};
</script>

<style lang="postcss" scoped></style>
