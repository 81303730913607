<template>
  <Base
    :tag="tag"
    :type="computedType"
    :href="computedHref"
    class="relative"
    :class="computedClass"
    :disabled="computedDisabled"
  >
    <div class="flex items-center" :class="{ invisible: loading }">
      <slot />
    </div>

    <Spinner size="small" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" :hidden="!loading" />
  </Base>
</template>

<script>
import Base from '@/fuse/components/Base.vue';
import Spinner from '@/fuse/components/Spinner.vue';

const SCHEME_DEFAULT = 'indigo';
const SCHEME_MAPPINGS = {
  indigo: ['base', 'indigo'],
  red: ['base', 'red'],
  white: ['base', 'white'],
  'indigo-outlined': ['base', 'indigo-outlined'],
  'red-outlined': ['base', 'red-outlined'],
  'indigo-text': ['text', 'indigo-text'],
  'red-text': ['text', 'red-text'],
};
const SCHEME_OPTIONS = Object.keys(SCHEME_MAPPINGS);

const SIZE_DEFAULT = 'default';
const SIZE_MAPPINGS = {
  default: null,
};
const SIZE_OPTIONS = Object.keys(SIZE_MAPPINGS);

const TAG_DEFAULT = 'button';
const TAG_OPTIONS = [TAG_DEFAULT, 'a', 'summary', 'div'];

const TYPE_DEFAULT = 'button';
const TYPE_OPTIONS = [null, TYPE_DEFAULT, 'reset', 'submit'];

export default {
  components: {
    Base,
    Spinner,
  },

  props: {
    scheme: {
      type: String,
      default: SCHEME_DEFAULT,
      validator(value) {
        return SCHEME_OPTIONS.includes(value);
      },
    },
    size: {
      type: String,
      default: SIZE_DEFAULT,
      validator(value) {
        return SIZE_OPTIONS.includes(value);
      },
    },
    tag: {
      type: String,
      default: TAG_DEFAULT,
      validator(value) {
        return TAG_OPTIONS.includes(value);
      },
    },
    type: {
      type: String,
      default: TYPE_DEFAULT,
      validator(value) {
        return TYPE_OPTIONS.includes(value);
      },
    },
    href: { type: String, default: null },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  computed: {
    computedDisabled() {
      return this.loading || this.disabled;
    },

    computedType() {
      return this.tag === 'button' ? this.type : null;
    },

    computedHref() {
      return this.tag === 'a' ? this.href : null;
    },

    schemeClasses() {
      return SCHEME_MAPPINGS[this.scheme] || [];
    },

    computedClass() {
      return this.schemeClasses.map((klass) => this.$style[klass]);
    },
  },

  mounted() {
    if (this.tag === 'a' && !this.href) {
      console.error('Invalid props on Button: href must be set when tag is a.');
    }
  },
};
</script>

<style lang="postcss" module>
.base {
  @apply inline-flex items-center;
  @apply px-4 py-2;
  @apply border border-transparent;
  @apply text-sm font-medium;
  @apply rounded-md shadow-sm;
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.indigo {
  @apply text-white bg-indigo-600;
  @apply hover:bg-indigo-700;
  @apply focus:ring-indigo-500;
}

.red {
  @apply text-white bg-red-600;
  @apply hover:bg-red-700;
  @apply focus:ring-red-500;
}

.white {
  @apply border border-gray-300;
  @apply text-gray-700 bg-white;
  @apply hover:bg-gray-50;
  @apply focus:ring-gray-500;
}

.indigo-outlined {
  @apply border border-indigo-500;
  @apply text-indigo-600 bg-white;
  @apply hover:bg-indigo-50;
  @apply focus:ring-indigo-500;
}

.red-outlined {
  @apply border border-red-500;
  @apply text-red-600 bg-white;
  @apply hover:bg-red-50;
  @apply focus:ring-red-500;
}

.text {
  @apply rounded-md;
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.indigo-text {
  @apply text-indigo-600;
  @apply hover:text-indigo-900;
  @apply focus:ring-indigo-500;
}

.red-text {
  @apply text-red-600;
  @apply hover:text-red-900;
  @apply focus:ring-red-500;
}
</style>
