<template>
  <tr v-if="!deleted">
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
    <td
      class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 font-mono"
      :class="{ 'text-opacity-50': revoked }"
      @click="revealPassword = !revealPassword"
    >
      <template v-if="revealPassword">
        {{ password }}
      </template>
      <template v-else>&middot;&middot;&middot;&middot;&middot;&middot;</template>
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-dark">
      {{ statusText }}
    </td>
    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
      <Button v-if="active" scheme="red-text" :loading="revoking" @click="revokeAdmin()">Revoke</Button>
      <!--<Button v-if="revoked" scheme="red-text" :loading="deleting" @click="deleteAdmin()">Delete</Button>-->
    </td>
  </tr>
</template>

<script>
import { revokeUser, deleteUser } from '@/admin_api';

import Button from '@/fuse/components/Button.vue';

export default {
  components: {
    Button,
  },

  props: {
    uuid: { type: String, required: true },
    password: { type: String, required: true },
    createdAt: { type: String, required: true },
    status: { type: String, required: true },
  },

  data() {
    return {
      revoking: false,
      deleting: false,
      statusValue: this.status,
      revealPassword: false,
    };
  },

  computed: {
    active() {
      return this.statusValue === 'active';
    },

    revoked() {
      return this.statusValue === 'revoked';
    },

    deleted() {
      return this.statusValue === 'deleted';
    },

    statusText() {
      if (this.active) return 'Active';
      if (this.revoked) return 'Revoked';
      if (this.deleted) return 'Deleted';

      return this.statusValue;
    },
  },

  methods: {
    setRevoked() {
      this.statusValue = 'revoked';
    },

    setDeleted() {
      this.statusValue = 'deleted';
    },

    revokeAdmin() {
      this.revoking = true;

      revokeUser({ userUuid: this.uuid })
        .then(() => {
          this.setRevoked();
          this.revoking = false;
        })
        .catch((err) => {
          console.warn('Revoking admin failed:', err);

          this.revoking = false;
        });
    },

    deleteAdmin() {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (!confirm('Are you sure you want to delete this admin?')) {
        return;
      }

      this.deleting = true;

      deleteUser({ userUuid: this.uuid })
        .then(() => {
          this.setDeleted();
          this.deleting = false;
        })
        .catch((err) => {
          console.warn('Deleting admin failed:', err);

          this.deleting = false;
        });
    },
  },
};
</script>
