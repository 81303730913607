import createLibraryApiBaseUrl from './helpers/create_library_api_base_url';

function getLibrary({ libraryUuid, source, page, jwt }) {
  const params = { source: source || '', page, jwt: jwt || '' };
  const paramsString = new URLSearchParams(params).toString();
  const itemsUrl = `${createLibraryApiBaseUrl()}/libraries/${libraryUuid}?${paramsString}`;

  return fetch(itemsUrl).then((res) => res.json());
}

function getPresentation({ libraryUuid, presentationId, jwt }) {
  const params = { jwt };
  const paramsString = new URLSearchParams(params).toString();
  const presentationUrl = `${createLibraryApiBaseUrl()}/libraries/${libraryUuid}/presentations/${presentationId}?${paramsString}`;

  return fetch(presentationUrl).then((res) => res.json());
}

function search({ libraryUuid, query, page, jwt }) {
  const params = { query, page: page || 1, jwt: jwt || '' };
  const paramsString = new URLSearchParams(params).toString();
  const searchUrl = `${createLibraryApiBaseUrl()}/libraries/${libraryUuid}/search?${paramsString}`;

  return fetch(searchUrl).then((res) => res.json());
}

export { getLibrary, getPresentation, search };
