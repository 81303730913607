<template>
  <div>
    <h1>Not Found</h1>
  </div>
</template>

<script>
import { useTitle } from '@vueuse/core';

export default {
  mounted() {
    useTitle('Not found · Playback');
  },
};
</script>
