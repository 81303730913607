<template>
  <Form />
</template>

<script>
import { useTitle } from '@vueuse/core';

import Form from '@/admin/components/libraries/Form.vue';

export default {
  components: {
    Form,
  },

  computed: {},

  mounted() {
    useTitle('New library · Playback Admin');
  },
};
</script>
