<template>
  <div v-if="loading" class="relative mx-auto mt-8">
    <Spinner class="mx-auto" size="large" />
  </div>

  <div v-else class="mt-8 flex flex-col">
    <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Password
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <AdminListRow
                v-for="admin in admins"
                :key="[adminsReloadCounter, admin.uuid].join('/')"
                :uuid="admin.uuid"
                :password="admin.password"
                :created-at="admin.created_at"
                :status="admin.status"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/fuse/components/Spinner.vue';

import { listUsers, createUser } from '@/admin_api';

import AdminListRow from './AdminsListRow.vue';

export default {
  components: {
    Spinner,
    AdminListRow,
  },

  emits: ['loaded'],

  data() {
    return {
      loading: true,
      adminsReloadCounter: 0,
      admins: [],
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.loading = true;

      return listUsers().then((json) => {
        this.admins = json.data.users;
        this.loading = false;
      });
    },

    reloadAdmins() {
      return listUsers().then((json) => {
        this.adminsReloadCounter += 1;
        this.admins = json.data.users;
      });
    },

    createAdmin() {
      this.creatingAdmin = true;

      createUser()
        .then(() => this.reloadAdmins())
        .then(() => {
          this.creatingAdmin = false;
        })
        .catch((err) => {
          console.warn('Error when creating admin:', err);
          this.creatingAdmin = false;
        });
    },
  },
};
</script>
