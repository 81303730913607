const DEFAULT_API_HOST = 'connectiv.api.onslideslive.com';

function createApiHost() {
  const host = window.location.host.split(':')[0];

  if (host === 'localhost') {
    return DEFAULT_API_HOST;
  }

  if (host.endsWith('.onslideslive.com') || host.endsWith('.onslideslive.com.test')) {
    const subdomain = host.split('.')[0];
    return `${subdomain}.api.onslideslive.com`;
  }

  throw new Error(`Unknown tennant: ${host}`);
}

export default createApiHost;
