<template>
  <Form :library-uuid="libraryUuid" />
</template>

<script>
import { useTitle } from '@vueuse/core';

import Form from '@/admin/components/libraries/Form.vue';

export default {
  components: {
    Form,
  },

  computed: {
    libraryUuid() {
      return this.$route.params.libraryUuid;
    },
  },

  mounted() {
    useTitle('Edit library · Playback Admin');
  },
};
</script>
