<template>
  <div v-if="loading" class="relative mx-auto p-5">
    <Spinner class="mx-auto" size="large" />
  </div>

  <div v-else class="p-5 sm:p-6 lg:p-8 space-y-6">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">
          <template v-if="persisted">Edit library</template>
          <template v-else>New library</template>
        </h1>
        <p class="mt-2 text-sm text-gray-700">You can change settings for selected library on this page.</p>
      </div>
      <!--<div v-if="persisted" class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <Button scheme="red">Delete library</Button>
      </div>-->
    </div>

    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 md:grid md:grid-cols-3 md:gap-6 sm:p-6 mt-8">
        <div class="md:col-span-1">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Library</h3>
          <p class="mt-1 text-sm text-gray-500"></p>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <div class="grid grid-cols-6 gap-6">
            <div v-if="persisted" class="col-span-6">
              <label for="background-url" class="block text-sm font-medium text-gray-700">UUID</label>
              <input
                id="background-url"
                v-model="library.uuid"
                type="text"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md font-mono"
                readonly
              />
            </div>

            <div v-if="persisted" class="col-span-6">
              <label for="background-url" class="block text-sm font-medium text-gray-700">URL</label>
              <input
                id="background-url"
                :value="libraryUrl"
                type="text"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                readonly
              />
            </div>

            <div class="col-span-6">
              <label for="slideslive-api-host" class="block text-sm font-medium text-gray-700"
                >SlidesLive API host</label
              >
              <input
                id="slideslive-api-host"
                v-model="library.slideslive_api_host"
                type="text"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            <div class="col-span-6">
              <label for="slideslive-account-id" class="block text-sm font-medium text-gray-700"
                >SlidesLive account ID</label
              >
              <input
                id="slideslive-account-id"
                v-model="library.slideslive_account_id"
                type="text"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />

              <p
                id="load-presentations-from-slideslive-account-description"
                class="text-gray-900 text-opacity-68 text-sm"
              >
                <template v-if="library.slideslive_account_id">
                  You can edit your SlidesLive account
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    class="underline text-indigo-600"
                    :href="`https://studio.slideslive.com/r/accounts/${library.slideslive_account_id}`"
                    >here</a
                  >.
                </template>

                <template v-else>
                  You can find your SlidesLive account ID in Developer section in your account edit page on SlidesLive.
                </template>
              </p>
            </div>

            <div class="col-span-6">
              <label for="slideslive-api-token" class="block text-sm font-medium text-gray-700"
                >SlidesLive API token</label
              >
              <input
                id="slideslive-api-token"
                v-model="library.slideslive_api_token"
                type="text"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md font-mono"
              />

              <p
                id="load-presentations-from-slideslive-account-description"
                class="text-gray-900 text-opacity-68 text-sm"
              >
                You can find your SlidesLive API token in Developer section in your account edit page on SlidesLive.
              </p>
            </div>

            <div class="col-span-6">
              <label for="jwt-expiration-time" class="block text-sm font-medium text-gray-700"
                >Playback JWT expiration time</label
              >
              <input
                id="jwt-expiration-time"
                v-model="library.jwt_expiration_time"
                type="text"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            <div class="col-span-6">
              <div class="relative">
                <div class="flex items-center">
                  <input
                    id="allow-insecure-access"
                    v-model="library.settings.allow_insecure_access"
                    aria-describedby="allow-insecure-access-description"
                    type="checkbox"
                    class="h-4 w-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label for="allow-insecure-access" class="font-medium text-gray-700 sm:text-sm ml-3"
                    >Allow insecure access</label
                  >
                </div>

                <p id="allow-insecure-access-description" class="text-gray-900 text-opacity-68 ml-7 text-sm">
                  When checked playback site can be loaded without a valid JWT. Using this option is not secure because
                  users can copy playback site URL and share it freely.
                </p>
              </div>
            </div>

            <div class="col-span-6">
              <div class="relative">
                <div class="flex items-center">
                  <input
                    id="load-presentations-from-slideslive-account"
                    v-model="library.settings.load_presentations_from_slideslive_account"
                    aria-describedby="load-presentations-from-slideslive-account-description"
                    type="checkbox"
                    class="h-4 w-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label
                    for="load-presentations-from-slideslive-account"
                    class="font-medium text-gray-700 sm:text-sm ml-3"
                    >Load presentations from SlidesLive account</label
                  >
                </div>

                <p
                  id="load-presentations-from-slideslive-account-description"
                  class="text-gray-900 text-opacity-68 ml-7 text-sm"
                >
                  When checked presentations from SlidesLive account will be automatically loaded on the playback site
                  below defined custom groups.
                </p>
              </div>
            </div>

            <div v-if="library.settings.load_presentations_from_slideslive_account" class="col-span-6">
              <div class="relative">
                <label for="slideslive-folder-ids" class="block text-sm font-medium text-gray-700"
                  >SlidesLive collections</label
                >
                <input
                  id="slideslive-folder-ids"
                  v-model="library.settings.slideslive_folder_ids"
                  type="text"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />

                <p
                  id="load-presentations-from-slideslive-account-description"
                  class="text-gray-900 text-opacity-68 text-sm"
                >
                  When automatic loading from SlidesLive account is enabled you can enter collection IDs here to limit
                  from which SlidesLive collections presentations are loaded.<br />You can find collection IDs on
                  SlidesLive account edit page. Enter them in the input above separated by a comma.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="persisted" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <Button :loading="savingLibrarySettings" @click="saveLibrarySettings()">Save</Button>
      </div>
    </div>

    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 md:grid md:grid-cols-3 md:gap-6 sm:p-6 mt-8">
        <div class="md:col-span-1">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Header</h3>
          <p class="mt-1 text-sm text-gray-500"></p>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <div class="grid grid-cols-6 gap-6">
            <div class="col-span-6">
              <label for="title" class="block text-sm font-medium text-gray-700">Title</label>
              <input
                id="title"
                v-model="library.header.title"
                type="text"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            <div class="col-span-6">
              <label for="logo-url" class="block text-sm font-medium text-gray-700">Logo URL</label>
              <input
                id="logo-url"
                v-model="library.header.logo_url"
                type="text"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            <div class="col-span-6">
              <label for="background-url" class="block text-sm font-medium text-gray-700">Background URL</label>
              <input
                id="background-url"
                v-model="library.header.background_url"
                type="text"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            <div class="col-span-3">
              <label for="height" class="block text-sm font-medium text-gray-700">Height</label>
              <input
                id="height"
                v-model="library.header.height"
                type="text"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <p
                id="load-presentations-from-slideslive-account-description"
                class="text-gray-900 text-opacity-68 text-sm"
              >
                Set height to 0 to hide the header.
              </p>
            </div>

            <div class="col-span-6">
              <label for="header-font-url" class="block text-sm font-medium text-gray-700">Font URL</label>
              <input
                id="header-font-url"
                v-model="library.header.font_url"
                type="text"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            <div class="col-span-6">
              <label for="header-font-style" class="block text-sm font-medium text-gray-700">Font style</label>
              <input
                id="header-font-style"
                v-model="library.header.font_style"
                type="text"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="!persisted" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <Button :loading="savingHeader" @click="createLibrary()">Create</Button>
      </div>

      <div v-if="persisted" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <Button :loading="savingHeader" @click="saveLibraryHeader()">Save</Button>
      </div>
    </div>

    <template v-if="persisted">
      <div class="bg-white shadow sm:rounded-lg px-4 py-5 sm:p-6 mt-8">
        <form action="#" method="POST">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Custom groups</h3>
              <p class="mt-1 text-sm text-gray-500">You can reorder custom groups by dragging them up or down.</p>
              <p class="mt-1 text-sm text-gray-500">
                To change and reorder custom group content open its Edit. Reorder the content by dragging sections with
                each item up or down.
              </p>
              <p class="mt-1 text-sm text-gray-500">Importing custom groups from CSV removes all previous content.</p>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <div class="flex flex-col">
                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Title
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Items</th>
                            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                          </tr>
                        </thead>

                        <draggable
                          v-model="library.custom_groups"
                          tag="tbody"
                          class="divide-y divide-gray-200 bg-white"
                          group="customGroups"
                          item-key="uuid"
                          @update="updateCustomGroupsOrderIds"
                        >
                          <template #item="{ element }">
                            <tr>
                              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {{ element.title }}
                              </td>
                              <td class="whitespace-nowrap px-3 py-4 text-sm text-dark">
                                {{ element?.items?.length || 'N/A' }}
                              </td>
                              <td
                                class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                              >
                                <Button scheme="indigo-text" @click="openCustomGroupEdit(element.uuid)">Edit</Button>
                              </td>
                            </tr>
                          </template>
                        </draggable>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-6 text-right flex space-x-2 justify-end">
            <Button @click="openCustomGroupImport(null)">Import</Button>
            <Button @click="openCustomGroupEdit(null)">Create new group</Button>
          </div>
        </form>
      </div>

      <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 mt-8">
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Library tokens</h3>
            <p class="mt-1 text-sm text-gray-500">
              Here you can create and revoke access tokens. These tokens are used to authenticate when creating URLs to
              access the Library.
            </p>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="flex flex-col">
              <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-300">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Token
                          </th>
                          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Created at
                          </th>
                          <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                        </tr>
                      </thead>
                      <tbody class="divide-y divide-gray-200 bg-white">
                        <TokenRow
                          v-for="token in library.tokens"
                          :key="[libraryTokensReloadCounter, token.uuid].join('/')"
                          :library-uuid="libraryUuid"
                          :token-uuid="token.uuid"
                          :token="token.token"
                          :created-at="token.created_at"
                          :status="token.status"
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-6 text-right">
              <Button :loading="creatingLibraryToken" @click="createNewLibraryToken()">Create new token</Button>
            </div>

            <div class="mt-6">
              <p class="mt-1 text-sm">
                To create playback URL for an iframe use an active Library token and do a
                <span class="font-mono">POST</span> request to
                <span class="font-mono">{{ libraryApiBaseUrl }}/libraries/{{ libraryUuid }}/create_url</span>.
              </p>
              <p class="mt-1 text-sm text-gray-500">To test the API you can use <span class="font-mono">curl</span>.</p>
              <p class="mt-1 text-sm text-gray-500">
                <span class="font-mono">
                  LIBRARY_TOKEN=&lt;token&gt;; curl -v -H 'Content-Type: application/json' --data "{\"token\":
                  \"$LIBRARY_TOKEN\"}" '{{ libraryApiBaseUrl }}/libraries/{{ libraryUuid }}/create_url'
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>

  <CustomGroupImport v-if="persisted" ref="customGroupImport" @saved="reloadCustomGroups()" />

  <CustomGroupEdit
    v-if="persisted"
    ref="customGroupEdit"
    @saved="reloadCustomGroups()"
    @deleted="reloadCustomGroups()"
  />
</template>

<script>
import draggable from 'vuedraggable';

import { createLibrary, createLibraryToken, getLibrary, updateLibrary, newLibrary } from '@/admin_api';

import Button from '@/fuse/components/Button.vue';
import Spinner from '@/fuse/components/Spinner.vue';
import createLibraryApiBaseUrl from '@/helpers/create_library_api_base_url';
import createAdminApiBaseUrl from '../../../helpers/create_admin_api_base_url';
import createFrontendHost from '../../../helpers/create_frontend_host';

import CustomGroupEdit from './CustomGroupEdit.vue';
import CustomGroupImport from './CustomGroupImport.vue';
import TokenRow from './TokenRow.vue';

export default {
  components: {
    draggable,
    Button,
    Spinner,
    CustomGroupEdit,
    CustomGroupImport,
    TokenRow,
  },

  props: {
    libraryUuid: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loading: true,
      library: { header: {}, custom_groups: {}, custom_groups_import_settings: {}, tokens: [] },

      savingLibrarySettings: false,
      savingHeader: false,
      creatingLibraryToken: false,
      customGroupsReloadCounter: 0,
      libraryTokensReloadCounter: 0,
    };
  },

  computed: {
    persisted() {
      return !!this.libraryUuid;
    },

    libraryUrl() {
      return `${window?.location?.protocol || 'https:'}//${createFrontendHost()}/libraries/${this.libraryUuid}`;
    },

    libraryApiBaseUrl() {
      return createLibraryApiBaseUrl();
    },

    adminApiBaseUrl() {
      return createAdminApiBaseUrl();
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      let promise;
      if (this.persisted) {
        promise = getLibrary({ libraryUuid: this.libraryUuid });
      } else {
        promise = newLibrary();
      }

      return promise.then((json) => {
        this.library = json.data.library;
        this.loading = false;
      });
    },

    reload() {
      return getLibrary({ libraryUuid: this.libraryUuid }).then((json) => {
        this.library = json.data.library;
      });
    },

    reloadCustomGroups() {
      return getLibrary({ libraryUuid: this.libraryUuid }).then((json) => {
        this.customGroupsReloadCounter += 1;
        this.library.custom_groups = json.data.library.custom_groups;
        this.library.custom_groups_import_settings = json.data.library.custom_groups_import_settings;
      });
    },

    reloadLibraryTokens() {
      return getLibrary({ libraryUuid: this.libraryUuid }).then((json) => {
        this.libraryTokensReloadCounter += 1;
        this.library.tokens = json.data.library.tokens;
      });
    },

    saveLibrarySettings() {
      this.savingLibrarySettings = true;

      const data = {
        slideslive_api_host: this.library.slideslive_api_host,
        slideslive_api_token: this.library.slideslive_api_token,
        slideslive_account_id: this.library.slideslive_account_id,
        jwt_expiration_time: this.library.jwt_expiration_time,
        settings: this.library.settings,
      };

      updateLibrary({
        libraryUuid: this.libraryUuid,
        library: data,
      })
        .then(() => {
          this.savingLibrarySettings = false;
        })
        .catch((err) => {
          console.warn('Error when saving library:', err);
          this.savingLibrarySettings = false;
        });
    },

    saveLibraryHeader() {
      this.savingHeader = true;

      updateLibrary({ libraryUuid: this.libraryUuid, library: { header: this.library.header } })
        .then(() => this.reload())
        .then(() => {
          this.savingHeader = false;
        })
        .catch((err) => {
          console.warn('Error when saving library header:', err);
          this.savingHeader = false;
        });
    },

    createLibrary() {
      this.savingHeader = true;

      const data = {
        slideslive_api_host: this.library.slideslive_api_host,
        slideslive_api_token: this.library.slideslive_api_token,
        slideslive_account_id: this.library.slideslive_account_id,
        jwt_expiration_time: this.library.jwt_expiration_time,
        settings: this.library.settings,
        header: this.library.header,
      };

      if (!data.header.title) {
        data.header.title = `New library (${new Date().toISOString()})`;
      }

      createLibrary({ library: data })
        .then((response) => {
          this.$router.push(`/admin/libraries/${response.data.library.uuid}/edit`);
        })
        .catch((err) => {
          console.warn('Error when creating library:', err);
          this.savingHeader = false;
        });
    },

    updateCustomGroupsOrderIds() {
      for (const [orderId, customGroup] of Object.entries(this.library.custom_groups)) {
        customGroup.order_id = parseInt(orderId, 10);
      }

      updateLibrary({
        libraryUuid: this.libraryUuid,
        library: { custom_groups: this.library.custom_groups },
      }).then(() => this.reloadCustomGroups());
    },

    openCustomGroupImport() {
      this.$refs.customGroupImport.show({
        libraryUuid: this.libraryUuid,
        customGroupsImportSettings: this.library.custom_groups_import_settings,
      });
    },

    openCustomGroupEdit(customGroupUuid) {
      this.$refs.customGroupEdit.show({ libraryUuid: this.libraryUuid, customGroupUuid });
    },

    createNewLibraryToken() {
      this.creatingLibraryToken = true;

      createLibraryToken({ libraryUuid: this.libraryUuid })
        .then(() => this.reloadLibraryTokens())
        .then(() => {
          this.creatingLibraryToken = false;
        })
        .catch((err) => {
          console.warn('Error when creating library token:', err);
          this.savingHeader = false;
        });
    },
  },
};
</script>
