<template>
  <div v-if="alerts.length > 0" class="space-y-6 z-50">
    <Alert
      v-for="alert in alerts"
      :key="[alert.uuid].join('/')"
      :uuid="alert.uuid"
      :type="alert.type"
      :title="alert.title"
      :dismissable="alert.dismissable"
      :dismiss-in="alert.dismissIn"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="alert.content" />
    </Alert>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Alert from '@/base/components/Alert.vue';

export default {
  components: {
    Alert,
  },

  computed: {
    ...mapState({ alerts: (state) => state.alerts }),
  },
};
</script>
