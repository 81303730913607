<template>
  <div class="rounded-md p-4" :class="bgClass">
    <div class="flex">
      <div class="flex-shrink-0">
        <XCircleIcon v-if="type === 'error'" class="h-5 w-5" :class="iconClass" aria-hidden="true" />
        <ExclamationIcon v-if="type === 'warning'" class="h-5 w-5" :class="iconClass" aria-hidden="true" />
        <InformationCircleIcon v-if="type === 'info'" class="h-5 w-5" :class="iconClass" aria-hidden="true" />
      </div>
      <div class="ml-3 flex flex-col justify-center">
        <h3 v-if="title" class="text-sm font-medium" :class="titleClass">{{ title }}</h3>
        <div class="text-sm" :class="textClass">
          <slot />
        </div>
      </div>
      <div v-if="dismissable" class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            type="button"
            class="inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2"
            :class="dimissClass"
            @click="dismiss()"
          >
            <span class="sr-only">Dismiss</span>
            <XIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XCircleIcon, ExclamationIcon, InformationCircleIcon, XIcon } from '@heroicons/vue/solid';

import { mapActions } from 'vuex';

export default {
  components: {
    XCircleIcon,
    ExclamationIcon,
    InformationCircleIcon,
    XIcon,
  },

  props: {
    uuid: { type: String, required: true },
    type: { type: String, required: true },
    title: { type: String, default: null },
    dismissable: { type: Boolean, default: false },
    dismissIn: { type: Number, default: null },
  },

  computed: {
    bgClass() {
      if (this.type === 'error') return 'bg-red-50';
      if (this.type === 'warning') return 'bg-yellow-50';
      if (this.type === 'info') return 'bg-blue-50';
      if (this.type === 'success') return 'bg-green-50';

      return '';
    },

    titleClass() {
      if (this.type === 'error') return 'text-red-800';
      if (this.type === 'warning') return 'text-yellow-800';
      if (this.type === 'info') return 'text-blue-800';
      if (this.type === 'success') return 'bg-green-800';

      return '';
    },

    textClass() {
      return {
        'mt-2': !!this.title,
        'text-red-700': this.type === 'error',
        'text-yellow-700': this.type === 'warning',
        'text-blue-700': this.type === 'info',
        'text-green-700': this.type === 'success',
      };
    },

    iconClass() {
      if (this.type === 'error') return 'text-red-400';
      if (this.type === 'warning') return 'text-yellow-400';
      if (this.type === 'info') return 'text-blue-400';
      if (this.type === 'success') return 'bg-green-400';

      return '';
    },

    dimissClass() {
      if (this.type === 'error')
        return 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600';
      if (this.type === 'warning')
        return 'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600';
      if (this.type === 'info')
        return 'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600';
      if (this.type === 'success')
        return 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600';

      return '';
    },
  },

  mounted() {
    if (this.dismissIn) {
      setTimeout(() => this.dismiss(), this.dismissIn);
    }
  },

  methods: {
    ...mapActions(['dismissAlert']),

    dismiss() {
      this.dismissAlert(this.uuid);
    },
  },
};
</script>
