<template>
  <div v-if="loading" class="relative mx-auto mt-8">
    <Spinner class="mx-auto" size="large" />
  </div>

  <div v-else class="mt-8 flex flex-col">
    <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Title
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">UUID</th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="item in items" :key="[item.uuid].join('/')">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {{ item.header.title }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-dark font-mono">{{ item.uuid }}</td>
                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <a :href="editLibraryUrl(item)" class="text-indigo-600 hover:text-indigo-900">Edit</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/fuse/components/Spinner.vue';

import { listLibraries } from '@/admin_api';

export default {
  components: {
    Spinner,
  },

  emits: ['loaded'],

  data() {
    return {
      loading: true,
      items: [],
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    editLibraryUrl(library) {
      return `/admin/libraries/${library.uuid}/edit`;
    },

    load() {
      this.loading = true;

      return listLibraries().then((json) => {
        this.items = json.data.libraries;
        this.loading = false;
      });
    },
  },
};
</script>
