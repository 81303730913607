<template>
  <div class="space-y-2">
    <div>
      <label for="custom-group-item-type" class="block text-sm font-medium text-gray-700">Type</label>
      <select
        id="custom-group-item-type"
        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
        :value="content.item_type"
        @input="update('item_type', $event.target.value)"
      >
        <option value="external_url">External URL</option>
        <option value="slideslive_presentation">SlidesLive presentation</option>
      </select>
    </div>

    <div v-if="externalUrl">
      <label for="custom-group-item-title" class="block text-sm font-medium text-gray-700">Title</label>
      <input
        id="custom-group-item-title"
        type="text"
        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
        :value="content.item_type_data.title"
        @input="updateItemTypeData('title', $event.target.value)"
      />
    </div>

    <div v-if="externalUrl">
      <label for="custom-group-item-thumbnail-url" class="block text-sm font-medium text-gray-700">Thumbnail URL</label>
      <input
        id="custom-group-item-thumbnail-url"
        type="text"
        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
        :value="content.item_type_data.thumbnail_url"
        @input="updateItemTypeData('thumbnail_url', $event.target.value)"
      />
    </div>

    <div v-if="externalUrl">
      <label for="custom-group-item-external-url" class="block text-sm font-medium text-gray-700">External URL</label>
      <input
        id="custom-group-item-external-url"
        type="text"
        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
        :value="content.item_type_data.external_url"
        @input="updateItemTypeData('external_url', $event.target.value)"
      />
    </div>

    <div v-if="externalUrl">
      <label for="custom-group-item-subtitle" class="block text-sm font-medium text-gray-700"
        >Subtitle (position, company, etc.)</label
      >
      <input
        id="custom-group-item-subtitle"
        type="text"
        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
        :value="content.item_type_data.subtitle"
        @input="updateItemTypeData('subtitle', $event.target.value)"
      />
    </div>

    <div v-if="externalUrl">
      <label for="custom-group-item-description" class="block text-sm font-medium text-gray-700"
        >Description (abstract)</label
      >
      <textarea
        id="custom-group-item-description"
        rows="4"
        class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        :value="content.item_type_data.description"
        @input="updateItemTypeData('description', $event.target.value)"
      />
    </div>

    <div v-if="slideslivePresentation">
      <label for="custom-group-item-slideslive-presentation-id" class="block text-sm font-medium text-gray-700"
        >SlidesLive presentation ID</label
      >
      <input
        id="custom-group-item-slideslive-presentation-id"
        type="text"
        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
        :value="content.item_type_data.slideslive_presentation_id"
        @input="updateItemTypeData('slideslive_presentation_id', $event.target.value)"
      />
    </div>

    <div v-if="content.uuid" class="mt-6 text-right">
      <Button scheme="red-outlined" @click="removeItem()">Remove item</Button>
    </div>
  </div>
</template>

<script>
import Button from '@/fuse/components/Button.vue';

export default {
  components: {
    Button,
  },

  props: {
    modelValue: { type: Object, required: true },
  },

  emits: ['update:modelValue', 'remove'],

  data() {
    return {
      content: this.modelValue,
    };
  },

  computed: {
    externalUrl() {
      return this.content.item_type === 'external_url';
    },

    slideslivePresentation() {
      return this.content.item_type === 'slideslive_presentation';
    },
  },

  watch: {
    modelValue(newVal) {
      this.content = newVal;
    },
  },

  methods: {
    update(key, value) {
      this.$emit('update:modelValue', { ...this.content, [key]: value });
    },

    updateItemTypeData(key, value) {
      this.$emit('update:modelValue', {
        ...this.content,
        item_type_data: { ...this.content.item_type_data, [key]: value },
      });
    },

    removeItem() {
      this.$emit('remove');
    },
  },
};
</script>
