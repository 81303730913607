import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import './main.css';

import libraryRoutes from './library/routes';
import adminRoutes from './admin/routes';

import store from './store';

import App from './App.vue';
import NotFound from './admin/pages/NotFound.vue';

const routes = [...libraryRoutes, ...adminRoutes, { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
    if (!store.getters.isAdminLoggedIn) {
      next({ name: 'admin/login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

const app = createApp(App);
app.use(store);
app.use(router);
app.mount('#app');
