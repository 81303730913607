import { createStore } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { logIn } from '@/admin_api';

export default createStore({
  state: {
    apiBaseUrl: null,
    adminToken: null,

    alerts: [],
  },

  getters: {
    isAdminLoggedIn(state) {
      return state.adminToken !== null;
    },
  },

  mutations: {
    setAdminToken(state, adminToken) {
      state.adminToken = adminToken;
    },

    dismissAlert(state, alertUuid) {
      const alertIndex = state.alerts.findIndex((alert) => alert.uuid === alertUuid);
      state.alerts.splice(alertIndex, 1);
    },

    showAlert(state, { type, content, title = null, dismissable = true, dismissIn = 6000 }) {
      state.alerts.push({
        uuid: '',
        type,
        title,
        dismissable,
        dismissIn,
        content,
      });
    },
  },

  actions: {
    showAlert({ commit }, { type, content, title = null, dismissable = true, dismissIn = 6000 }) {
      commit('showAlert', { type, content, title, dismissable, dismissIn });
    },

    dismissAlert({ commit }, alertUuid) {
      commit('dismissAlert', alertUuid);
    },

    logInAdmin({ commit }, password) {
      return logIn({ password })
        .then((json) => {
          if (json.success) {
            localStorage.setItem('adminToken', json.data.jwt);
            commit('setAdminToken', json.data.jwt);
          } else {
            localStorage.removeItem('adminToken');
            commit('setAdminToken', null);
          }
        })
        .catch(() => {
          localStorage.removeItem('adminToken', null);
          commit('setAdminToken', null);
        });
    },

    fetchAdminToken({ commit }) {
      commit('setAdminToken', localStorage.getItem('adminToken'));
    },

    resetAdminToken({ commit }) {
      commit('setAdminToken', null);
      localStorage.removeItem('adminToken', null);
    },
  },
});
