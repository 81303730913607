<template>
  <Base :class="computedClass">
    <Icon name="spinner" :class="computedIconClass" />
  </Base>
</template>

<script>
import Base from '@/fuse/components/Base.vue';
import Icon from '@/fuse/components/Icon.vue';

const SIZE_DEFAULT = 'medium';
const SIZE_MAPPINGS = {
  small: ['w-4', 'h-4'],
  [SIZE_DEFAULT]: ['w-8', 'h-8'],
  large: ['w-16', 'h-16'],
};
// const SIZE_OPTIONS = Object.keys(SIZE_MAPPINGS);

export default {
  components: {
    Base,
    Icon,
  },

  props: {
    size: { type: String, default: SIZE_DEFAULT },
    dark: { type: Boolean, default: true },
  },

  computed: {
    computedClass() {
      return SIZE_MAPPINGS[this.size];
    },

    computedIconClass() {
      const iconClass = ['animate-spin', 'block', 'w-full', 'h-full', 'pointer-events-none'];
      if (!this.dark) iconClass.push('text-white');
      if (this.dark) iconClass.push('text-dark');
      return iconClass;
    },
  },
};
</script>
