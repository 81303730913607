// eslint-disable-next-line import/no-cycle
import store from './store/index';

import createAdminApiBaseUrl from './helpers/create_admin_api_base_url';

function fetchApiUrl(url, { method = 'GET', contentType = null, body = null, withAdminToken = true } = {}) {
  const headers = { Accept: 'application/json' };

  if (contentType) {
    headers['Content-Type'] = contentType;
  }

  if (withAdminToken) {
    headers.Authorization = `Bearer ${store.state.adminToken}`;
  }

  return fetch(url, {
    method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'omit',
    headers,
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body,
  }).then((res) => {
    if (res.status === 401) {
      store.dispatch('resetAdminToken');
      window.location.reload();
      return {};
    }

    return res.json();
  });
}

function getApiUrl(url, { withAdminToken = true } = {}) {
  return fetchApiUrl(url, { withAdminToken });
}

function postApiUrl(url, { contentType = 'application/json', body, withAdminToken = true } = {}) {
  return fetchApiUrl(url, { method: 'POST', contentType, body, withAdminToken });
}

function patchApiUrl(url, { contentType = 'application/json', body, withAdminToken = true } = {}) {
  return fetchApiUrl(url, { method: 'PATCH', contentType, body, withAdminToken });
}

function deleteApiUrl(url, { withAdminToken = true } = {}) {
  return fetchApiUrl(url, { method: 'DELETE', withAdminToken });
}

function logIn({ password }) {
  return postApiUrl(`${createAdminApiBaseUrl()}/auth/token`, {
    body: JSON.stringify({ password }),
    withAdminToken: false,
  });
}

function listUsers() {
  return getApiUrl(`${createAdminApiBaseUrl()}/users`);
}

function createUser() {
  return postApiUrl(`${createAdminApiBaseUrl()}/users`);
}

function revokeUser({ userUuid }) {
  return postApiUrl(`${createAdminApiBaseUrl()}/users/${userUuid}/revoke`);
}

function deleteUser({ userUuid }) {
  return deleteApiUrl(`${createAdminApiBaseUrl()}/users/${userUuid}`);
}

function listLibraries() {
  return getApiUrl(`${createAdminApiBaseUrl()}/libraries`);
}

function getLibrary({ libraryUuid }) {
  return getApiUrl(`${createAdminApiBaseUrl()}/libraries/${libraryUuid}`);
}

function newLibrary() {
  return getApiUrl(`${createAdminApiBaseUrl()}/libraries/new`);
}

function createLibrary({ library }) {
  return postApiUrl(`${createAdminApiBaseUrl()}/libraries/create`, { body: JSON.stringify({ library }) });
}

function updateLibrary({ libraryUuid, library }) {
  return patchApiUrl(`${createAdminApiBaseUrl()}/libraries/${libraryUuid}`, { body: JSON.stringify({ library }) });
}

function deleteLibrary({ libraryUuid }) {
  return deleteApiUrl(`${createAdminApiBaseUrl()}/libraries/${libraryUuid}`);
}

function getLibraryCustomGroup({ libraryUuid, customGroupUuid }) {
  return getApiUrl(`${createAdminApiBaseUrl()}/libraries/${libraryUuid}/custom_groups/${customGroupUuid}`);
}

function newLibraryCustomGroup({ libraryUuid }) {
  return getApiUrl(`${createAdminApiBaseUrl()}/libraries/${libraryUuid}/custom_groups/new`);
}

function createLibraryCustomGroup({ libraryUuid, customGroup }) {
  return postApiUrl(`${createAdminApiBaseUrl()}/libraries/${libraryUuid}/custom_groups/create`, {
    body: JSON.stringify({ library_custom_group: customGroup }),
  });
}

function updateLibraryCustomGroup({ libraryUuid, customGroupUuid, customGroup }) {
  return patchApiUrl(`${createAdminApiBaseUrl()}/libraries/${libraryUuid}/custom_groups/${customGroupUuid}`, {
    body: JSON.stringify({ library_custom_group: customGroup }),
  });
}

function deleteLibraryCustomGroup({ libraryUuid, customGroupUuid }) {
  return deleteApiUrl(`${createAdminApiBaseUrl()}/libraries/${libraryUuid}/custom_groups/${customGroupUuid}`);
}

function importLibraryCustomGroups({ libraryUuid, customGroups, replaceContent, importSettings }) {
  return postApiUrl(`${createAdminApiBaseUrl()}/libraries/${libraryUuid}/custom_groups/replace`, {
    body: JSON.stringify({
      library_custom_groups: customGroups,
      replace_content: replaceContent,
      library_custom_groups_import_settings: importSettings,
    }),
  });
}

function createLibraryToken({ libraryUuid }) {
  return postApiUrl(`${createAdminApiBaseUrl()}/libraries/${libraryUuid}/tokens/create`);
}

function revokeLibraryToken({ libraryUuid, tokenUuid }) {
  return postApiUrl(`${createAdminApiBaseUrl()}/libraries/${libraryUuid}/tokens/${tokenUuid}/revoke`);
}

function deleteLibraryToken({ libraryUuid, tokenUuid }) {
  return deleteApiUrl(`${createAdminApiBaseUrl()}/libraries/${libraryUuid}/tokens/${tokenUuid}`);
}

export {
  logIn,
  listUsers,
  createUser,
  revokeUser,
  deleteUser,
  listLibraries,
  getLibrary,
  newLibrary,
  createLibrary,
  updateLibrary,
  deleteLibrary,
  getLibraryCustomGroup,
  newLibraryCustomGroup,
  createLibraryCustomGroup,
  updateLibraryCustomGroup,
  deleteLibraryCustomGroup,
  importLibraryCustomGroups,
  createLibraryToken,
  revokeLibraryToken,
  deleteLibraryToken,
};
