<template>
  <div class="flex h-screen">
    <div class="p-5 sm:p-6 lg:p-8 space-y-6 w-full max-w-md m-auto">
      <div>
        <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            id="password"
            v-model="password"
            type="password"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div class="text-right">
        <Button :loading="logInLoading" @click="logIn()">Log in</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { useTitle } from '@vueuse/core';

import { mapActions } from 'vuex';

import Button from '@/fuse/components/Button.vue';

export default {
  components: {
    Button,
  },

  data() {
    return {
      logInLoading: false,
      password: null,
    };
  },

  methods: {
    ...mapActions(['logInAdmin']),

    logIn() {
      this.logInLoading = true;
      this.logInAdmin(this.password).then(() => {
        this.logInLoading = false;
        this.$router.push({ path: '/admin' });
      });
    },

    mounted() {
      useTitle('Edit library · Playback Admin');
    },
  },
};
</script>
