<template>
  <div class="p-5 sm:p-6 lg:p-8">
    <div>
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">Admin passwords</h1>
          <p class="mt-2 text-sm text-gray-700">
            A list of all your admin passwords which can be used to access this admin interface. Click on the password
            to reveal it.
          </p>
        </div>
        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Button @click="createAdmin()"> Create admin password </Button>
        </div>
      </div>

      <AdminsList ref="adminsList" />
    </div>

    <div class="mt-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">Libraries</h1>
          <p class="mt-2 text-sm text-gray-700">A list of all your libraries.</p>
        </div>
        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Button tag="a" :href="newLibraryUrl()"> Add library </Button>
        </div>
      </div>

      <LibrariesList ref="list" />
    </div>

    <div class="mt-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">Documentation</h1>
          <p class="mt-2 text-sm text-gray-700">
            Documentation for playback site hosting is
            <a
              target="_blank"
              rel="noopener noreferrer"
              class="underline text-indigo-600"
              href="https://www.notion.so/slideslive/Playback-site-documentation-a9bb5614e9454438b4f0ae8dad92c6ad?pvs=4"
              >here</a
            >.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useTitle } from '@vueuse/core';

import Button from '@/fuse/components/Button.vue';

import AdminsList from '@/admin/components/AdminsList.vue';
import LibrariesList from '@/admin/components/libraries/List.vue';

export default {
  components: {
    Button,
    AdminsList,
    LibrariesList,
  },

  mounted() {
    useTitle('Playback Admin');
  },

  methods: {
    newLibraryUrl() {
      return `/admin/libraries/new`;
    },

    createAdmin() {
      this.$refs.adminsList.createAdmin();
    },
  },
};
</script>
