<template>
  <div v-if="loading" class="relative mx-auto p-5">
    <Spinner class="mx-auto" size="large" />
  </div>

  <div :hidden="loading" class="relative mx-auto space-y-5 pb-5">
    <Header
      v-if="!headerHidden"
      :height="header.height"
      :background-url="header.background_url"
      :logo-url="header.logo_url"
      :title="header.title"
      :font-url="header.font_url"
      :font-style="header.font_style"
      :library-url="libraryUrl"
    />

    <Base class="xl:container mx-auto space-y-5 px-5">
      <Base class="flex justify-left">
        <Button tag="a" class="my-auto ml-0" scheme="white" :href="libraryUrl">
          <ArrowLeftIcon class="w-5 h-5 mr-1" aria-hidden="true" />
          Back to Library
        </Button>
      </Base>

      <div id="slideslive-presentation-embed"></div>

      <Base class="space-y-2">
        <Heading level="h2" custom-class="text-gray-800 text-2xl tracking-normal font-semibold">{{
          presentation.title
        }}</Heading>
        <Text class="text-opacity-50" scheme="small">{{ formattedRecordedAt }}</Text>
      </Base>

      <Base v-if="presentation.speakers.length > 0" class="space-y-2">
        <Heading class="text-opacity-68" level="h3">Speakers</Heading>

        <template v-for="speaker in presentation.speakers" :key="speaker.name">
          <Text
            >{{ speaker.name }}<span v-if="speaker.title">, {{ speaker.title }}</span></Text
          >
        </template>
      </Base>

      <Base v-if="presentation.description" class="space-y-2">
        <Heading class="text-opacity-68" level="h3">About</Heading>

        <Text>{{ presentation.description }}</Text>
        <Text v-if="customGroupItem?.item_type_data?.tags" class="hidden">
          {{ customGroupItem?.item_type_data?.tags }}
        </Text>
      </Base>

      <Base v-if="customGroupItem?.item_type_data?.tags" class="space-y-2">
        <Heading class="text-opacity-68" level="h3">Themes</Heading>

        <Text>{{ customGroupItem?.item_type_data?.tags }}</Text>
      </Base>
    </Base>
  </div>
</template>

<script>
import { ArrowLeftIcon } from '@heroicons/vue/solid';

import { useTitle } from '@vueuse/core';

import { getPresentation } from '@/api';
import Spinner from '@/fuse/components/Spinner.vue';
import Base from '@/fuse/components/Base.vue';
import Heading from '@/fuse/components/Heading.vue';
import Text from '@/fuse/components/Text.vue';
import Button from '@/fuse/components/Button.vue';

import Header from './library_iframe/Header.vue';

export default {
  components: {
    ArrowLeftIcon,
    Spinner,
    Base,
    Heading,
    Text,
    Button,

    Header,
  },

  data() {
    return {
      loading: true,

      header: {},

      presentation: {
        title: null,
        description: null,
        speakers: [],
        recordedAt: null,
      },

      customGroup: null,
      customGroupItem: null,

      embed: null,
      scrollListener: null,
    };
  },

  computed: {
    headerHidden() {
      return this.header.height === 0 || this.header.height === '0' || this.header.height === '0px';
    },

    libraryUrl() {
      const jwt = this.$route.query.jwt || '';
      return `/libraries/${this.$route.params.libraryUuid}?jwt=${jwt}`;
    },

    recordedAt() {
      return new Date(this.presentation.recordedAt);
    },

    formattedRecordedAt() {
      return new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(this.recordedAt);
    },
  },

  mounted() {
    this.loadPresentation();
    this.loadEmbed();
  },

  methods: {
    loadPresentation() {
      getPresentation({
        libraryUuid: this.$route.params.libraryUuid,
        presentationId: this.$route.params.id,
        jwt: this.$route.query.jwt,
      }).then((json) => {
        this.header = json.data.header;
        useTitle(this.header.title);

        this.presentation.title = json.data.presentation.title;
        this.presentation.description = json.data.presentation.description;
        this.presentation.recordedAt = json.data.presentation.recorded_at;
        this.presentation.speakers = json.data.presentation.speakers;

        this.customGroup = json.data.custom_group;
        this.customGroupItem = json.data.custom_group_item;

        this.loading = false;
      });
    },

    loadEmbed() {
      // eslint-disable-next-line no-undef
      this.embed = new SlidesLiveEmbed('slideslive-presentation-embed', {
        presentationId: this.$route.params.id,
        autoPlay: false,
        verticalEnabled: true,
      });
    },
  },
};
</script>
