<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-68 transition-opacity" />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative bg-white rounded-lg my-5 text-left overflow-hidden shadow-xl transform transition-all max-w-xl w-full"
            >
              <div class="text-left px-4 py-5 sm:p-6">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  <template v-if="persisted">Edit custom group</template>
                  <template v-else>Create custom group</template>
                </DialogTitle>

                <div v-if="loading" class="relative mx-auto p-5 mt-2 w-full">
                  <Spinner class="mx-auto" size="large" />
                </div>

                <div v-else class="mt-6 w-full space-y-6">
                  <div>
                    <label for="custom-group-title" class="block text-sm font-medium text-gray-700">Title</label>
                    <input
                      id="custom-group-title"
                      v-model="customGroup.title"
                      type="text"
                      class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <draggable
                    ref="itemsList"
                    v-model="customGroup.items"
                    tag="ul"
                    class="space-y-4"
                    group="customGroupsItems"
                    item-key="uuid"
                    @update="updateCustomGroupItemsOrderIds"
                  >
                    <template #item="{ index }">
                      <li class="bg-gray-50 shadow overflow-hidden rounded-md p-4">
                        <CustomGroupItem
                          v-model="customGroup.items[index]"
                          @remove="removeItemAtIndex(index)"
                        ></CustomGroupItem>
                      </li>
                    </template>
                  </draggable>

                  <div class="mt-6 text-right">
                    <Button scheme="indigo-outlined" @click="addItem()">Add new item</Button>
                  </div>
                </div>
              </div>

              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-between space-x-2">
                <div>
                  <Button
                    v-if="persisted"
                    scheme="red-outlined"
                    :loading="deleting"
                    :disabled="deleting || saving"
                    @click="deleteGroup()"
                    >Delete group</Button
                  >
                </div>

                <div class="flex space-x-2">
                  <Button scheme="white" :disabled="deleting || saving" @click="hide()">Cancel</Button>
                  <Button type="submit" :loading="saving" :disabled="loading || deleting || saving" @click="saveGroup()"
                    >Save</Button
                  >
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import draggable from 'vuedraggable';

import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import {
  getLibraryCustomGroup,
  updateLibraryCustomGroup,
  deleteLibraryCustomGroup,
  newLibraryCustomGroup,
  createLibraryCustomGroup,
} from '@/admin_api';

import Button from '@/fuse/components/Button.vue';
import Spinner from '@/fuse/components/Spinner.vue';

import CustomGroupItem from './CustomGroupItem.vue';

const EMPTY_CUSTOM_GROUP_ITEM = {
  order_id: 1,
  item_type: 'external_url',
  item_type_data: {},
};

export default {
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    draggable,
    Button,
    Spinner,
    CustomGroupItem,
  },

  emits: ['saved', 'deleted'],

  data() {
    return {
      loading: true,
      libraryUuid: null,
      customGroupUuid: null,
      open: false,

      customGroup: null,

      itemsSortable: null,

      saving: false,
      deleting: false,
    };
  },

  computed: {
    persisted() {
      return !!this.customGroupUuid;
    },
  },

  methods: {
    show({ libraryUuid, customGroupUuid }) {
      this.loading = true;
      this.libraryUuid = libraryUuid;
      this.customGroupUuid = customGroupUuid;
      this.open = true;

      this.load();
    },

    hide() {
      this.open = false;
    },

    load() {
      let promise;
      if (this.persisted) {
        promise = getLibraryCustomGroup({ libraryUuid: this.libraryUuid, customGroupUuid: this.customGroupUuid });
      } else {
        promise = newLibraryCustomGroup({ libraryUuid: this.libraryUuid });
      }

      return promise.then((json) => {
        this.customGroup = json.data.library_custom_group;
        this.afterLoad();
      });
    },

    afterLoad() {
      this.loading = false;
    },

    updateCustomGroupItemsOrderIds() {
      for (const [orderId, item] of Object.entries(this.customGroup.items)) {
        item.order_id = parseInt(orderId, 10);
      }
    },

    addItem() {
      this.customGroup.items.push({ ...EMPTY_CUSTOM_GROUP_ITEM, order_id: this.customGroup.items.length });
    },

    removeItemAtIndex(index) {
      this.customGroup.items.splice(index, 1);
    },

    saveGroup() {
      this.saving = true;

      let promise;
      if (this.persisted) {
        promise = updateLibraryCustomGroup({
          libraryUuid: this.libraryUuid,
          customGroupUuid: this.customGroupUuid,
          customGroup: this.customGroup,
        });
      } else {
        promise = createLibraryCustomGroup({
          libraryUuid: this.libraryUuid,
          customGroup: this.customGroup,
        });
      }

      promise
        .then(() => {
          this.saving = false;
          this.$emit('saved');
          this.hide();
        })
        .catch((err) => {
          console.warn('Error when saving library custom group:', err);
          this.saving = false;
        });
    },

    deleteGroup() {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (!confirm('Are you sure you want to delete this group?')) {
        return;
      }

      this.deleting = true;

      deleteLibraryCustomGroup({ libraryUuid: this.libraryUuid, customGroupUuid: this.customGroupUuid })
        .then(() => {
          this.deleting = false;
          this.$emit('deleted');
          this.hide();
        })
        .catch((err) => {
          console.warn('Error when deleting library custom group:', err);
          this.deleting = false;
        });
    },
  },
};
</script>
