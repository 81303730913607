<template>
  <Base :tag="tag" :class="computedClass"><slot /></Base>
</template>

<script>
import Base from '@/fuse/components/Base.vue';

const SCHEME_DEFAULT = 'default';
const SCHEME_MAPPINGS = {
  [SCHEME_DEFAULT]: 'base',
  large: 'large',
  small: 'small',
  xsmall: 'xsmall',
};
// const SCHEME_OPTIONS = Object.keys(SCHEME_MAPPINGS);

const SCHEME_CLASSES = {
  base: ['text-gray-900', 'text-sm', 'sm:text-base', 'tracking-tight', 'sm:tracking-normal'],
  large: ['text-gray-900', 'text-base', 'sm:text-lg', 'tracking-normal'],
  small: ['text-gray-900', 'text-sm', 'tracking-normal', 'sm:tracking-tight', 'font-medium'],
  xsmall: ['text-gray-900', 'text-xs', 'tracking-normal', 'font-medium'],
};

export default {
  components: {
    Base,
  },

  props: {
    tag: { type: String, default: 'p' },
    scheme: { type: String, default: SCHEME_DEFAULT },
  },

  computed: {
    computedTag() {
      if (this.level === 'sub') return 'p';

      return this.level;
    },

    computedClass() {
      return SCHEME_CLASSES[SCHEME_MAPPINGS[this.scheme]];
    },
  },
};
</script>
