import createApiHost from './create_api_host';

function createAdminApiBaseUrl() {
  const host = createApiHost();
  const proto = host === 'localhost' || host.startsWith('localhost:') ? 'http' : 'https';

  return `${proto}://${host}/admin/api/v1`;
}

export default createAdminApiBaseUrl;
