import createApiHost from './create_api_host';

function createLibraryApiBaseUrl() {
  const host = createApiHost();
  const proto = host === 'localhost' || host.startsWith('localhost:') ? 'http' : 'https';

  return `${proto}://${host}/api/v1`;
}

export default createLibraryApiBaseUrl;
