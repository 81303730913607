<template>
  <Base class="mx-auto sm:max-w-full group w-full max-w-68 relative focus-within:z-10">
    <Thumbnail :href="href" :target="target" :src="thumbnailUrl" />

    <Base class="relative flex flex-col justify-between py-2">
      <Heading
        level="h4"
        custom-class="pr-10 line-clamp-2 text-gray-800 text-base tracking-normal font-normal leading-snug"
      >
        <a :href="href" :target="target">{{ title }}</a>
      </Heading>
    </Base>
  </Base>
</template>

<script>
import Base from '@/fuse/components/Base.vue';
import Heading from '@/fuse/components/Heading.vue';

import Thumbnail from './presentation_box/Thumbnail.vue';

export default {
  components: {
    Base,
    Heading,

    Thumbnail,
  },

  props: {
    title: { type: String, required: true },
    thumbnailUrl: { type: String, required: true },
    href: { type: String, required: true },
    target: { type: String, required: true },
  },
};
</script>
