<template>
  <Base :tag="computedTag" :class="computedClass">
    <slot />
  </Base>
</template>

<script>
// const LEVEL_OPTIONS = ['h1', 'h2', 'h3', 'h4', 'sub'];

import Base from '@/fuse/components/Base.vue';

const LEVEL_CLASSES = {
  h1: ['text-gray-800', 'text-xl', 'sm:text-2xl', 'tracking-normal', 'sm:tracking-tight', 'font-black'],
  h2: ['text-gray-800', 'text-lg', 'sm:text-xl', 'tracking-normal', 'font-black'],
  h3: ['text-gray-800', 'text-lg', 'sm:text-xl', 'tracking-normal', 'font-bold'],
  h4: ['text-gray-800', 'text-base', 'sm:text-lg', 'tracking-normal', 'font-black'],
  sub: ['text-gray-800', 'text-sm', 'tracking-wider', 'font-medium'],
};

export default {
  components: {
    Base,
  },

  props: {
    level: { type: String, default: 'h2' },
    levelClass: { type: String, default: null },
    customClass: { type: String, default: null },
  },

  computed: {
    computedTag() {
      if (this.level === 'sub') return 'p';

      return this.level;
    },

    computedClass() {
      return this.customClass || LEVEL_CLASSES[this.levelClass || this.level];
    },
  },
};
</script>
