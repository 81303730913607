<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-68 transition-opacity" />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative bg-white rounded-lg my-5 text-left overflow-hidden shadow-xl transform transition-all max-w-xl w-full"
            >
              <div class="text-left px-4 py-5 sm:p-6">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  Import custom groups
                </DialogTitle>

                <div class="mt-6 w-full space-y-6">
                  <div>
                    <label for="data-source" class="block text-sm font-medium text-gray-700">Data source</label>
                    <select
                      id="data-source"
                      v-model="dataSource"
                      class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
                    >
                      <option value="csv">CSV</option>
                    </select>
                  </div>

                  <div v-if="dataSource === 'csv'">
                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="import-csv"
                      >Select CSV</label
                    >
                    <input
                      id="import-csv"
                      class="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      type="file"
                      @change="loadCsv"
                    />
                  </div>

                  <div v-if="dataSource === 'google_spreadsheet'">
                    <label for="google-spreadsheet" class="block text-sm font-medium text-gray-700"
                      >Google Spreadsheet ID (link)</label
                    >
                    <input
                      id="google-spreadsheet"
                      v-model="googleSpreadsheet"
                      type="text"
                      class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div>
                    <label for="first-data-row-number" class="block text-sm font-medium text-gray-700"
                      >First data row number</label
                    >
                    <input
                      id="first-data-row-number"
                      v-model="firstDataRowNumber"
                      type="text"
                      class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div>
                    <label for="last-data-row-number" class="block text-sm font-medium text-gray-700"
                      >Last data row number</label
                    >
                    <input
                      id="last-data-row-number"
                      v-model="lastDataRowNumber"
                      type="text"
                      class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div>
                    <label for="skip-column" class="block text-sm font-medium text-gray-700">Skip column</label>
                    <input
                      id="skip-column"
                      v-model="skipColumn"
                      type="text"
                      class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div>
                    <label for="categories-columns" class="block text-sm font-medium text-gray-700"
                      >Categories column</label
                    >
                    <input
                      id="categories-columns"
                      v-model="categoriesColumn"
                      type="text"
                      class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div>
                    <label for="tags-columns" class="block text-sm font-medium text-gray-700">Tags column</label>
                    <input
                      id="tags-columns"
                      v-model="tagsColumn"
                      type="text"
                      class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div>
                    <label for="slideslive-id-column" class="block text-sm font-medium text-gray-700"
                      >SlidesLive ID (link) column</label
                    >
                    <input
                      id="slideslive-id-column"
                      v-model="slidesLiveIdColumn"
                      type="text"
                      class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div class="flex items-center">
                    <input
                      id="replace-all-content"
                      v-model="replaceContent"
                      type="checkbox"
                      class="h-4 w-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                    <label for="replace-all-content" class="font-medium text-gray-700 sm:text-sm ml-3"
                      >Replace all content (will delete previous playback site content)</label
                    >
                  </div>

                  <div v-if="customGroupCount > 0">
                    <p class="text-base">
                      {{ customGroupCount }} custom groups will be imported with {{ presentationCount }} presentation
                      and {{ externalUrlCount }} external URLs.
                    </p>
                  </div>

                  <div v-else>
                    <p class="text-base">
                      Select CSV you want to import to replace current custom groups and their content.
                    </p>
                  </div>

                  <div>
                    <p v-if="replaceContent" class="text-base text-red-600">
                      All existing custom groups, presentations and external URLs will be deleted from playback site.
                    </p>

                    <p v-else class="text-base text-red-600">
                      Existing custom groups, presentations and external URLs will be kept. Only new custom groups,
                      presentations and external URLs will be added from CSV.
                    </p>
                  </div>
                </div>
              </div>

              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end space-x-2">
                <Button scheme="white" :disabled="saving" @click="hide()">Cancel</Button>
                <Button
                  type="submit"
                  :loading="saving"
                  :disabled="customGroupCount === 0 || saving"
                  @click="importCustomGroups()"
                  >Import</Button
                >
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import { parse } from 'csv-parse/browser/esm/sync';

import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { importLibraryCustomGroups } from '@/admin_api';

import Button from '@/fuse/components/Button.vue';

export default {
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Button,
  },

  emits: ['saved', 'deleted'],

  data() {
    return {
      libraryUuid: null,
      open: false,

      dataSource: 'csv',

      csv: null,
      googleSpreadsheet: null,

      firstDataRowNumber: 5,
      lastDataRowNumber: '',

      skipColumn: '',

      categoriesColumn: 'AI',
      categoriesSeparator: ',',

      tagsColumn: '',

      slidesLiveIdColumn: 'AK',

      externalUrlColumn: '',
      externalUrlTitleColumn: '',
      externalUrlThumbnailUrlColumn: '',
      externalUrlSubtitleColumn: '',
      externalUrlDescriptionColumn: '',

      replaceContent: true,

      customGroups: [],

      saving: false,
    };
  },

  computed: {
    persisted() {
      return !!this.customGroupUuid;
    },

    columnIndexes() {
      return {
        skipColumn: this.parseColumn(this.skipColumn),

        categoriesColumn: this.parseColumn(this.categoriesColumn),
        tagsColumn: this.parseColumn(this.tagsColumn),

        slidesLiveIdColumn: this.parseColumn(this.slidesLiveIdColumn),

        externalUrlColumn: this.parseColumn(this.externalUrlColumn),
        externalUrlTitleColumn: this.parseColumn(this.externalUrlTitleColumn),
        externalUrlThumbnailUrlColumn: this.parseColumn(this.externalUrlThumbnailUrlColumn),
        externalUrlSubtitleColumn: this.parseColumn(this.externalUrlSubtitleColumn),
        externalUrlDescriptionColumn: this.parseColumn(this.externalUrlDescriptionColumn),
      };
    },

    customGroupCount() {
      return this.customGroups.length;
    },

    presentationCount() {
      return this.customGroups
        .map((group) => group.items.filter((i) => i.item_type === 'slideslive_presentation').length)
        .reduce((acc, v) => acc + v, 0);
    },

    externalUrlCount() {
      return this.customGroups
        .map((group) => group.items.filter((i) => i.item_type === 'external_url').length)
        .reduce((acc, v) => acc + v, 0);
    },
  },

  watch: {
    firstDataRowNumber() {
      this.parseCsv();
    },
    lastDataRowNumber() {
      this.parseCsv();
    },
    skipColumn() {
      this.parseCsv();
    },
    categoriesColumn() {
      this.parseCsv();
    },
    tagsColumn() {
      this.parseCsv();
    },
    categoriesSeparator() {
      this.parseCsv();
    },
    slidesLiveIdColumn() {
      this.parseCsv();
    },
  },

  methods: {
    show({ libraryUuid, customGroupsImportSettings }) {
      this.libraryUuid = libraryUuid;
      this.initializeImportSettings(customGroupsImportSettings || {});
      this.open = true;
    },

    initializeImportSettings(importSettings) {
      const {
        dataSource = 'csv',
        googleSpreadsheet = null,

        firstDataRowNumber = 5,
        lastDataRowNumber = '',

        skipColumn = '',

        categoriesColumn = 'AI',
        categoriesSeparator = ',',

        tagsColumn = '',

        slidesLiveIdColumn = 'AK',

        externalUrlColumn = '',
        externalUrlTitleColumn = '',
        externalUrlThumbnailUrlColumn = '',
        externalUrlSubtitleColumn = '',
        externalUrlDescriptionColumn = '',

        replaceContent = true,
      } = importSettings;

      this.dataSource = dataSource;
      this.googleSpreadsheet = googleSpreadsheet;

      this.firstDataRowNumber = firstDataRowNumber;
      this.lastDataRowNumber = lastDataRowNumber;

      this.skipColumn = skipColumn;

      this.categoriesColumn = categoriesColumn;
      this.categoriesSeparator = categoriesSeparator;

      this.tagsColumn = tagsColumn;

      this.slidesLiveIdColumn = slidesLiveIdColumn;

      this.externalUrlColumn = externalUrlColumn;
      this.externalUrlTitleColumn = externalUrlTitleColumn;
      this.externalUrlThumbnailUrlColumn = externalUrlThumbnailUrlColumn;
      this.externalUrlSubtitleColumn = externalUrlSubtitleColumn;
      this.externalUrlDescriptionColumn = externalUrlDescriptionColumn;

      this.replaceContent = replaceContent;
    },

    hide() {
      this.open = false;
    },

    loadCsv(event) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = () => {
        this.csv = reader.result;
        this.parseCsv();
      };

      reader.onloadend = function () {};

      reader.readAsText(file);
    },

    parseCsv() {
      if (!this.csv) {
        return;
      }

      const csv = parse(this.csv, {});

      const firstDataRow = this.firstDataRowNumber - 1;
      const lastDataRow =
        !this.lastDataRowNumber || this.lastDataRowNumber === ''
          ? csv.length - 1
          : Math.min(csv.length - 1, this.lastDataRowNumber - 1);

      let customGroupOrderIdGenerator = 0;
      const customGroups = {};

      for (let rowIndex = firstDataRow; rowIndex <= lastDataRow; ++rowIndex) {
        const row = csv[rowIndex];

        if (this.skipRow(row)) {
          continue;
        }

        const categories = this.categoriesFromCsvRow(row);
        if (categories.length === 0) {
          continue;
        }

        console.log(categories);

        const customGroupItem = this.customGroupItemFromCsvRow(row);
        if (!customGroupItem) {
          continue;
        }

        for (const category of categories) {
          if (!customGroups[category]) {
            customGroups[category] = {
              title: category,
              order_id: customGroupOrderIdGenerator++,
              items: [],
            };
          }

          customGroups[category].items.push({ ...customGroupItem, order_id: customGroups[category].items.length });
        }
      }

      console.log(customGroups);

      this.customGroups = Object.values(customGroups);
    },

    customGroupItemFromCsvRow(csvRow) {
      const columns = this.columnIndexes;
      let item;

      if (this.parseSlidesLiveId(csvRow[columns.slidesLiveIdColumn])) {
        item = {
          item_type: 'slideslive_presentation',
          item_type_data: {
            slideslive_presentation_id: this.parseSlidesLiveId(csvRow[columns.slidesLiveIdColumn]),
            vimeo_video_id: null,
          },
        };
      } else if (this.presence(csvRow[columns.externalUrlColumn])) {
        item = {
          item_type: 'external_url',
          item_type_data: {
            title: this.presence(csvRow[columns.externalUrlTitleColumn]),
            thumbnail_url: this.presence(csvRow[columns.externalUrlThumbnailUrlColumn]),
            external_url: this.presence(csvRow[columns.externalUrlColumn]),
            subtitle: this.presence(csvRow[columns.externalUrlSubtitleColumn]),
            description: this.presence(csvRow[columns.externalUrlDescriptionColumn]),
          },
        };
      }

      if (!item) return null;

      const tags = this.presence(csvRow[columns.tagsColumn]);
      if (tags && tags.length > 0 && tags.toLowerCase() !== 'n/a') {
        item.item_type_data.tags = tags;
      }

      return item;
    },

    skipRow(csvRow) {
      const columns = this.columnIndexes;
      // if (columns.skipColumn == null) return false;

      const skip = this.presence(csvRow[columns.skipColumn]);
      if (skip && (skip.toLowerCase() === 'x' || skip.toLowerCase() === 'yes')) {
        return true;
      }

      return false;
    },

    categoriesFromCsvRow(csvRow) {
      const columns = this.columnIndexes;
      let categoriesString = this.presence(csvRow[columns.categoriesColumn]);
      if (!categoriesString) {
        return [];
      }

      if (categoriesString[0] === '[' && categoriesString[categoriesString.length - 1] === ']') {
        categoriesString = categoriesString.substring(1, categoriesString.length - 1);
      }

      categoriesString = this.presence(categoriesString);
      if (!categoriesString) {
        return [];
      }

      return categoriesString
        .split(this.categoriesSeparator)
        .map((c) => this.presence(c))
        .filter((c) => c.length > 0);
    },

    importCustomGroups() {
      this.saving = true;

      const promise = importLibraryCustomGroups({
        libraryUuid: this.libraryUuid,
        customGroups: this.customGroups,
        replaceContent: this.replaceContent,
        importSettings: {
          dataSource: this.dataSource,
          googleSpreadsheet: this.googleSpreadsheet,

          firstDataRowNumber: this.firstDataRowNumber,
          lastDataRowNumber: this.lastDataRowNumber,

          skipColumn: this.skipColumn,

          categoriesColumn: this.categoriesColumn,
          categoriesSeparator: this.categoriesSeparator,

          tagsColumn: this.tagsColumn,

          slidesLiveIdColumn: this.slidesLiveIdColumn,

          externalUrlColumn: this.externalUrlColumn,
          externalUrlTitleColumn: this.externalUrlTitleColumn,
          externalUrlThumbnailUrlColumn: this.externalUrlThumbnailUrlColumn,
          externalUrlSubtitleColumn: this.externalUrlSubtitleColumn,
          externalUrlDescriptionColumn: this.externalUrlDescriptionColumn,

          replaceContent: this.replaceContent,
        },
      });

      promise
        .then(() => {
          this.saving = false;
          this.$emit('saved');
          this.hide();
        })
        .catch((err) => {
          console.warn('Error when importing library custom groups:', err);
          this.saving = false;
        });
    },

    parseColumn(lettersOrIndex) {
      if (typeof lettersOrIndex === 'string' && lettersOrIndex.length === 0) return null;

      return this.isInteger(lettersOrIndex) ? parseInt(lettersOrIndex, 10) : this.columnLettersToIndex(lettersOrIndex);
    },

    columnLettersToIndex(letters) {
      const firstLetterCode = 'A'.charCodeAt(0);
      const lastLetterCode = 'Z'.charCodeAt(0);
      const letterCount = lastLetterCode - firstLetterCode + 1;

      let columnIndex = 0;
      for (let index = 0; index < letters.length; ++index) {
        const letter = letters[index];
        columnIndex += (letter.charCodeAt(0) - firstLetterCode + 1) * letterCount ** (letters.length - index - 1);
      }
      return columnIndex - 1;
    },

    isInteger(str) {
      if (typeof str !== 'string') return false;

      return !Number.isNaN(str) && !Number.isNaN(parseFloat(str)) && Number.isInteger(parseInt(str, 10));
    },

    presence(str) {
      if (!str) {
        return null;
      }

      str = str.trim();
      if (str.length === 0) {
        return null;
      }

      return str;
    },

    parseSlidesLiveId(slidesliveId) {
      slidesliveId = this.presence(slidesliveId);
      if (!slidesliveId) {
        return null;
      }

      const PRESENTATION_IDS_RX = [/^(?<id>[0-9]+)$/, /\/(?<id>[0-9]+)$/, /\/(?<id>[0-9]+)\//];

      for (const rx of PRESENTATION_IDS_RX) {
        const parsedId = slidesliveId.trim().match(rx)?.groups?.id;
        if (parsedId) {
          return parsedId;
        }
      }

      return null;
    },
  },
};
</script>
