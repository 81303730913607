<template>
  <div :class="computedClass">
    <slot />
  </div>
</template>

<script>
const COLS_DEFAULT = 4;
// const COLS_OPTIONS = [COLS_DEFAULT, 1, 2, 3];

const TYPE_DEFAULT = 'presentation';
// const TYPE_OPTIONS = [TYPE_DEFAULT, 'narrow', 'other'];

function presentationColsClass(cols) {
  switch (cols) {
    case 2:
      return ['grid-cols-auto-presentation-box', 'sm:grid-cols-2', '2xl:grid-cols-3'];

    case 3:
      return ['grid-cols-auto-presentation-box', 'sm:grid-cols-2', 'md:grid-cols-3', '2xl-grid-cols-4'];

    case 4:
      return [
        'grid-cols-auto-presentation-box',
        'sm:grid-cols-2',
        'md:grid-cols-3',
        'lg:grid-cols-4',
        '2xl:grid-cols-5',
      ];

    default:
      return ['grid-cols-1'];
  }
}

function narrowColsClass(cols) {
  switch (cols) {
    case 2:
      return ['grid-cols-1', 'lg:grid-cols-2'];

    case 3:
      return ['grid-cols-1', 'lg:grid-cols-2', 'xl:grid-cols-3'];

    case 4:
      return ['grid-cols-1', 'lg:grid-cols-2', 'xl:grid-cols-3', '2xl:grid-cols-4'];

    default:
      return ['grid-cols-1'];
  }
}

function otherColsClass(cols) {
  switch (cols) {
    case 2:
      return ['grid-cols-1', 'md:grid-cols-2', '2xl:grid-cols-3'];

    case 3:
      return ['grid-cols-1', 'md:grid-cols-2', 'lg:grid-cols-3', '2xl-grid-cols-4'];

    case 4:
      return ['grid-cols-1', 'md:grid-cols-2', 'lg:grid-cols-3', '2xl:grid-cols-4'];

    default:
      return ['grid-cols-1'];
  }
}

export default {
  props: {
    cols: { type: Number, default: COLS_DEFAULT },
    type: { type: String, default: TYPE_DEFAULT },
  },

  computed: {
    colsClass() {
      if (this.type === 'presentation') return presentationColsClass(this.cols);
      if (this.type === 'narrow') return narrowColsClass(this.cols);

      return otherColsClass(this.cols);
    },
    computedClass() {
      return ['grid', 'gap-4', this.colsClass];
    },
  },
};
</script>
