<template>
  <div class="relative px-10 py-6 flex justify-center items-center custom-font" :style="computedStyle">
    <a :href="libraryUrl">
      <div class="xl:container mx-auto space-y-5">
        <img v-if="logoUrl" :src="logoUrl" class="mx-auto" alt="Header logo" />
        <h1
          ref="title"
          class="text-xl sm:text-2xl tracking-normal sm:tracking-heading font-black text-white text-center"
          :class="computedFontClass"
          :style="fontStyle"
        >
          {{ title }}
        </h1>
      </div>
    </a>

    <div class="absolute inset-0 -z-1 bg-cover bg-center" :style="`background-image: url(${backgroundUrl});`"></div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    height: { type: [String, Number], default: 300 },
    backgroundUrl: { type: String, default: null },
    logoUrl: { type: String, default: null },
    title: { type: String, default: null },
    fontUrl: { type: String, default: null },
    fontStyle: { type: String, default: null },
    libraryUrl: { type: String, default: null },
  },

  data() {
    return {
      font: null,
      fontFamily: 'HeaderFontXYZ',
    };
  },

  computed: {
    customFont() {
      return this.fontFamily && this.fontUrl;
    },

    computedStyle() {
      const style = [`min-height: ${this.computedHeight}px`];
      return style.join(';');
    },

    computedFontClass() {
      return {
        [this.$style['reset-font']]: true,
        [this.$style['custom-font']]: this.customFont,
      };
    },

    computedHeight() {
      return parseInt(this.height, 10) || 300;
    },

    computedFontSize() {
      return parseInt(this.fontSize, 10) || 30;
    },
  },

  watch: {
    fontUrl() {
      this.loadFont();
    },
  },

  mounted() {
    this.loadFont();
  },

  methods: {
    loadFont() {
      if (!this.fontUrl) {
        return;
      }

      this.font = new FontFace(this.fontFamily, `url(${this.fontUrl})`);
      this.font.load().then((font) => {
        document.fonts.add(font);
      });
    },
  },
};
</script>

<style lang="postcss" module>
.reset-font {
  line-height: 1;
}
.custom-font {
  font-family:
    HeaderFontXYZ,
    Roboto,
    Helvetica Neue,
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Arial,
    Noto Sans,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol,
    Noto Color Emoji;
}
</style>
