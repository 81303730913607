<template>
  <Alerts class="fixed left-0 right-0 top-0 mx-6 mt-6" />
  <router-view></router-view>
</template>

<script>
import { mapActions } from 'vuex';
import Alerts from './base/components/Alerts.vue';

export default {
  name: 'App',
  components: { Alerts },
  created() {
    this.fetchAdminToken();
  },
  methods: {
    ...mapActions(['fetchAdminToken']),
  },
};
</script>
